import React, {Component} from 'react';
import {ImpCollapsible} from "../Util/ImpCollapsible";

var JSONPretty = require('react-json-pretty');
var JSONPrettyMon = require('react-json-pretty/dist/monikai');
const {hashPhoneNumberIdentifier} = require('../Util/cryptoUtils');


class ContactList extends Component {

    constructor(props) {
        super(props);
        console.log("Constructing MobileAdmin")
        this.state = {};

    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.userContext !== prevProps.userContext) {
            this.setState({"contact_filter":"ALL"});
        }
    }
    setContactFilter(cf)
    {
        this.setState({'contact_filter':cf})
    }

    render() {

        const layoutStyle = {
            margin: 20,
            padding: 20,
            border: '1px solid #DDD'
        };
        let contactFilter = this.state.contact_filter;

        let cnts;

        if (this.props.contacts !== undefined) {
            cnts = this.props.contacts;
            console.log(`contacts =  ${JSON.stringify(cnts)}`)
        } else {
            cnts = [];
        }

        let cntToShow = [], phonesToShow = [], i = 0, j = 0;
        for (let cnt of cnts) {

            let contactToShow, phoneToShow;
            let rowColor = "orange";

            let onList;
            if (!!cnt.ContactAssignment && !!cnt.ContactAssignment.list) {
                onList = cnt.ContactAssignment.list;
            }

            if (onList === "RED") {
                if(contactFilter==="GREY"||contactFilter==="GREEN")
                {
                    continue;
                }
                rowColor = "#ff0515";
            } else if (onList === "GREEN") {
                if(contactFilter==="GREY"||contactFilter==="RED")
                {
                    continue;
                }
                rowColor = "#0bb92d";
            } else if (onList === "GREY")   {
                if(contactFilter==="RED"||contactFilter==="GREEN" )
                {
                    continue;
                }
                rowColor = "#919191";
            } else   {
                //This is the color if No List : i.e. data corruption.
                if(contactFilter==="RED"||contactFilter==="GREEN" || contactFilter==="GREY")
                {
                    continue;
                }
                rowColor = "#455fff";
            }
            // //console.log("Contact = " + JSON.stringify(cnt));
            phonesToShow = [];

            let contactMD = {};
            if (!!cnt.ContactMD) {
                contactMD = cnt.ContactMD;
            } else {
                console.log(`No metadata associated with contact ${cnt.contact_id} `);
            }

            //console.log("contact: "+JSON.stringify(cnt.ContactMD));

            if(!!cnt.PhoneNumbers) {
                for (let phone of cnt.PhoneNumbers) {

                    let compoundID = `${phone.normalized_number}`;
                    let hashID = hashPhoneNumberIdentifier(compoundID);

                    phoneToShow =
                        <div style={{'color': "brown"}} key={"pn_" + phone.phone_number_id} className={'subList'}>
                            <div style={{'width': '850px'}} className={'listItem'}>ID: {hashID}&nbsp;&nbsp;&nbsp; Phone
                                Number: {phone.phone_number} Normalized:{phone.normalized_number}</div>
                            <div className={'listEnd'}></div>
                        </div>

                    phonesToShow.push(phoneToShow)
                    j++;

                }
            } else {
                console.log(`No Phone numbers associated with contact ${cnt.contact_id} `);
            }

            contactToShow = <div style={{'color': rowColor}} key={"contact_" + cnt.contact_uuid} className={'listRow'}>
                <div style={{'width': '300px'}} className={'listItem'}>{cnt.contact_id}</div>
                <div style={{'width': '40px'}} className={'listItem'}>{cnt.addressbook_type}</div>
                <div style={{'width': '80px'}} className={'listItem'}>{cnt.first_name}</div>
                <div style={{'width': '80px'}} className={'listItem'}>{cnt.last_name}</div>
                <ImpCollapsible collapsed={true} expandText="Details" header={''}>

                    <div style={{
                        'width': '700px',
                        'marginLeft': 50,
                        'textAlign': 'left',
                        'margin': '50',
                        'padding': 30,
                        "border": '0px solid #DDD'
                    }}>
                        <JSONPretty id="json-pretty" data={contactMD} theme={JSONPrettyMon}></JSONPretty>
                    </div>

                </ImpCollapsible>
                {phonesToShow}
                <div className={'listEnd'}></div>
            </div>

            cntToShow.push(contactToShow)
            i++;
        }

        let cntCount;
        if (!!cntToShow) {
            //if (!cntToShow || cntToShow.length === 0) {
            //cntToShow = "No contacts for this mobile."
            cntCount = `${cntToShow.length} contacts`;
        }

        return (
            <div style={layoutStyle}>
                <div style={{backgroundColor:"#efefef", padding:"3px" ,borderBottomStyle:"solid"}}>
                    Contacts to Show: &nbsp; &nbsp;
                    <input type="radio" id="contactFilter"
                           name="contactFilter" value="ALL"  defaultChecked={true} onClick={()=>this.setContactFilter("ALL")} />&nbsp;
                    <label htmlFor="onlineStatus_online">All</label> &nbsp; &nbsp;
                    <input type="radio" id="contactFilter"
                           name="contactFilter" value="GREEN" onClick={()=>this.setContactFilter("GREEN")} />&nbsp;
                    <label htmlFor="onlineStatus_online">Green</label> &nbsp; &nbsp;
                    <input type="radio" id="contactFilter"
                           name="contactFilter" value="GREY" onClick={()=>this.setContactFilter("GREY")} />&nbsp;
                    <label htmlFor="onlineStatus_online">Grey</label> &nbsp; &nbsp;
                    <input type="radio" id="contactFilter"
                           name="contactFilter" value="RED" onClick={()=>this.setContactFilter("RED")} />&nbsp;
                    <label htmlFor="onlineStatus_online">Red</label> &nbsp; &nbsp;

                </div>
                <div>{cntCount}</div>
                <div>{cntToShow}</div>
            </div>
        );
    }
}

export default ContactList;
